export const TotalTable = [
    "trials",
    "direct_sub",
    "paid_trials",
    "trial_to_sub",
    "arpu_1",
    "arpu_2",
    "arpu_12",
    "arpu_ad_1_day",
];

export default TotalTable;