import React, {useEffect, useState} from 'react';
import "../styles/appList.scss";
import axios from "axios";

function CurrentABTests() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                const response = await axios.get(
                    `https://newapi.godoko-fsp.com/currentAbTests`,
                    {
                        headers: {
                            Authorization: 'Token 0a7b58445700962080613374fe7144d5290343b6',
                        }
                    }
                );
                setData(response?.data);
                setLoading(false)
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDataFromApi().then(() => {});
    }, []);



    function renderItem(item, key, name) {
        return (
            <div className="ab_test_item" key={key + name}>
                <div className="ab_test_item__package">
                    <img src={item.package_icon} alt=""/>
                    <div className="ab_test_item__package_computer">{item.computer}</div>
                    <div className="ab_test_item__package_name">{item.package}</div>
                    <div className="ab_test_item__package_comment">{item.comment}</div>
                </div>
                {item.versions ? item.versions.map((itemVersion, keyVersion) => {
                    return (
                        <div className="ab_test_item__package_version" key={keyVersion+itemVersion}>
                            <span>version</span>
                            <span>{itemVersion}</span>
                        </div>
                    )
                }) : null}
            </div>
        )
    }

    return (
        loading ? <div className="loader_page"><div className="lds-dual-ring"></div></div> :
            <div className="page ab_test">
                <div className="ab_test_item__title">AB Design</div>
                {data.ab_design.map((item, key) => {
                    return (renderItem(item, key, 'Design'))
                })}
                <div className="ab_test_item__title">AB Subscriptions</div>
                {data.ab_subscriptions.map((item, key) => {
                    return (renderItem(item, key, 'Subscriptions'))
                })}
                <div className="ab_test_item__title">AB Icon or Screenshots</div>
                {data.ab_icon.map((item, key) => {
                    return (renderItem(item, key, 'Screenshots'))
                })}
            </div>
    );
}

export default CurrentABTests;