import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import logoDashboard from '../img/logoDashboard.svg';
import closed_sidebar from '../img/closed_sidebar.svg'

const SideBar = ({openState}) => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const [isOpen, setIsOpen] = openState;
    const handleTrigger = () => setIsOpen(!isOpen);

    return (
        <div className={
            (splitLocation[1] !== "geo_settings" && splitLocation[1] !== "package_settings") ? "sidebar overview_month" : "sidebar"
            }>
            <div className="sidebar__top">
                <img className="logo" src={logoDashboard} alt="sidebar logo"/>
                {(splitLocation[1] !== "geo_settings" && splitLocation[1] !== "package_settings")
                    ? <img src={closed_sidebar} className="sidebar__hide" onClick={handleTrigger} alt=""/>
                    : null
                }
            </div>
            <Link
                to="/overview_month"
                className={
                    (splitLocation[1] === "overview_month" || splitLocation[1] === "")
                        ? 'sidebar__link active'
                        : 'sidebar__link'
                }
                onClick={handleTrigger}
            >
                <div className="sidebar__link__icon overview"></div>
                <div className='sidebar__link__name'>Overview Monthly</div>
            </Link>
            <Link
                to="/overview"
                className={
                splitLocation[1] === "overview"
                    ? 'sidebar__link active'
                    : 'sidebar__link'
                }
                onClick={handleTrigger}
            >
                <div className="sidebar__link__icon overview"></div>
                <div className='sidebar__link__name'>Overview</div>
            </Link>
            <Link
                to="/app_list"
                className={
                    splitLocation[1] === "app_list"
                        ? 'sidebar__link active'
                        : 'sidebar__link'
                }
            >
                <div className="sidebar__link__icon apps_i"></div>
                <div className='sidebar__link__name'>App list</div>
            </Link>
            <Link
                to="/rtb_geo"
                className={
                    splitLocation[1] === "rtb_geo"
                        ? 'sidebar__link active'
                        : 'sidebar__link'
                }
            >
                <div className="sidebar__link__icon rtb_geo"></div>
                <div className='sidebar__link__name'>GEOs</div>
            </Link>
            <Link
                to="/geo_adwords"
                className={
                    splitLocation[1] === "geo_adwords"
                        ? 'sidebar__link active'
                        : 'sidebar__link'
                }
            >
                <div className="sidebar__link__icon geo_adwords"></div>
                <div className='sidebar__link__name'>GEOs Adwords</div>
            </Link>
            <Link
                to="/geo_settings"
                className={
                    splitLocation[1] === "geo_settings"
                        ? 'sidebar__link active'
                        : 'sidebar__link'
                }
            >
                <div className="sidebar__link__icon geo_settings"></div>
                <div className='sidebar__link__name'>Geo settings</div>
            </Link>
            <Link
                to="/package_settings"
                className={
                splitLocation[1] === "package_settings"
                    ? 'sidebar__link active'
                    : 'sidebar__link'
            }>
                <div className="sidebar__link__icon package_settings"></div>
                <div className='sidebar__link__name'>Package settings</div>
            </Link>
            <Link
                to="/app_list_version"
                className={
                    splitLocation[1] === "app_list_version"
                        ? 'sidebar__link active'
                        : 'sidebar__link'
                }>
                <div className="sidebar__link__icon apps_i"></div>
                <div className='sidebar__link__name'>Anal Apps list</div>
            </Link>
            <Link
                to="/current_ab_tests"
                className={
                    splitLocation[1] === "current_ab_tests"
                        ? 'sidebar__link active'
                        : 'sidebar__link'
                }>
                <div className="sidebar__link__icon apps_i"></div>
                <div className='sidebar__link__name'>Current AB tests</div>
            </Link>
            {/*<Link*/}
            {/*    to="/app_details_day"*/}
            {/*    className={*/}
            {/*        splitLocation[1] === "app_details_day"*/}
            {/*            ? 'sidebar__link active'*/}
            {/*            : 'sidebar__link'*/}
            {/*    }>*/}
            {/*    <div className="sidebar__link__icon apps_i"></div>*/}
            {/*    <div className='sidebar__link__name'>Anal App Detail Day</div>*/}
            {/*</Link>*/}
        </div>
    );
};

export default SideBar;