import React  from "react";
import Select from "react-select";

const CountrySelect = (parameters) => {
    const [loading, setLoading] = parameters.loading;
    const [selectStates, setSelectStates] = parameters.selectStates;
    const [selectedOption, setSelectedOption] = parameters.selectedOption;

    const countryAvailable = [];

    parameters.countryData.map(item => {
        if(item.available) {
            countryAvailable.push({
                label: `(${item.code}) ${item.country_name}`,
                value: item.code
            });
        }
    });

    const styleSelect = {
        control: (provided) => ({...provided, fontSize: '12px'}),
        menuList: base => ({...base, maxHeight: "200px"}),
        indicatorSeparator: () => ({ display: "none" }),
        indicatorsContainer: () => ({ display: "none" }),
    };

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setSelectStates((prevState) => (
            {
                ...prevState,
                [selectedOption.value]: true,
            }
        ));
       setLoading(true);
    };

    return (
       <div className="selected_wrapper">
           <Select
               value={selectedOption}
               onChange={handleSelectChange}
               options={countryAvailable}
               isSearchable
               className="filters__search_input"
               placeholder="Search"
               styles={styleSelect}
           />
       </div>
    );
}

export default CountrySelect;