import React, {useEffect, useState} from 'react';
import FiltersAppListVersion from "../components/AppListVersion/FiltersAppListVersion";
import TableAppListVersion from "../components/AppListVersion/TableAppListVersion";
import Moment from "moment";
import axios from "axios";

function AppListVersion() {
    const [loading, setLoading] = useState(true);
    const [selectedOption, setSelectedOption] = useState(null);
    const [countryData, setCountryData] = useState();
    const [selectStates, setSelectStates] = useState([]);
    const [dateRange, setDateRange] = useState([
        new Date(Date.now() - 8 * 24 * 60 * 60 * 1000),
        new Date(Date.now() - 24 * 60 * 60 * 1000),
    ]);
    const [dataListVersion, setDataListVersion] = useState([]);
    const [data, setData] = useState([]);

    const [selectedOptionVersion, setSelectedOptionVersion] = useState([]);
    const [appOptionVersion, setAppOptionVersion] = useState([]);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                const responseCountry = await axios.get(
                    `https://gpanalytic.net/analytic/getAllCountries`,
                    {
                        headers: {
                            Authorization: 'Token 0a7b58445700962080613374fe7144d5290343b6',
                        }
                    }
                );

                setCountryData(responseCountry?.data.Countries);

                const responseAppListVersion = await axios.post(
                    `https://gpanalytic.net/analytic/AnalAppList`,
                    {
                        "start_date": Moment(dateRange[0]).format('YYYY-MM-DD').toString(),
                        "end_date": Moment(dateRange[1]).format('YYYY-MM-DD').toString(),
                        "countries": selectedOption ? [selectedOption.value]: [],
                        "organic": true,
                        "facebook": true,
                        "google": true,
                        "other": true
                    },
                    {
                        headers: {
                            Authorization: 'Token 0a7b58445700962080613374fe7144d5290343b6',
                        }
                    }
                );

                setDataListVersion(responseAppListVersion?.data);

                const arrVersions = [];

                 if (responseAppListVersion?.data.App_list) {
                     responseAppListVersion?.data.App_list.map(async item => {
                         arrVersions.push({
                             [item.package]: Object.keys(item.versions)[1] ? [
                                 Object.keys(item.versions)[0], Object.keys(item.versions)[1]
                             ] : [
                                 Object.keys(item.versions)[0]
                             ]
                         });
                     });

                     if(Object.entries(selectedOptionVersion).length > 0) {
                         arrVersions.map((item, index) => {
                             let valueFirstSelect = selectedOptionVersion[Object.entries(item)[0][0]]?.first?.value;
                             let valueSecondSelect = selectedOptionVersion[Object.entries(item)[0][0]]?.second?.value;

                             if(valueFirstSelect) {
                                 item[Object.entries(item)[0][0]][0] = valueFirstSelect;
                             }
                             if(valueSecondSelect) {
                                 item[Object.entries(item)[0][0]][1] = valueSecondSelect;
                             }
                         })
                     }

                     if(arrVersions) {
                         const response = await axios.post(
                             `https://gpanalytic.net/analytic/AnalApps`,
                             {
                                 "start_date": Moment(dateRange[0]).format('YYYY-MM-DD').toString(),
                                 "end_date": Moment(dateRange[1]).format('YYYY-MM-DD').toString(),
                                 "countries": selectedOption ? [selectedOption.value]: [],
                                 "organic": true,
                                 "facebook": true,
                                 "google": true,
                                 "other": true,
                                 "apps": arrVersions
                             },
                             {
                                 headers: {
                                     Authorization: 'Token 0a7b58445700962080613374fe7144d5290343b6',
                                 }
                             }
                         );
                         setData(response?.data)
                     }
                 }

                setLoading(false)
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
               setLoading(false);
            }
        };

        fetchDataFromApi().then(() => {});
    }, [selectedOption, dateRange, selectedOptionVersion]);

    return (
         loading ? <div className="loader_page"><div className="lds-dual-ring"></div></div> :
            <div className="page apps versions">
                <FiltersAppListVersion
                    countryData={countryData}
                    loading={[loading, setLoading]}
                    selectedOption={[selectedOption, setSelectedOption]}
                    selectStates={[selectStates, setSelectStates]}
                    dateRange={[dateRange, setDateRange]}
                />
                <TableAppListVersion
                    loading={[loading, setLoading]}
                    dataDataListVersion={dataListVersion}
                    allPackageVersionTable={data}
                    dateRange={[dateRange, setDateRange]}
                    selectVersion={[selectedOptionVersion, setSelectedOptionVersion]}
                    appOptionVersion={[appOptionVersion, setAppOptionVersion]}
                />
            </div>
    );
}

export default AppListVersion;