import React, {useEffect, useState} from 'react';
import Moment from "moment";
import axios from "axios";
import FiltersAppListVersion from "../components/AppListVersion/FiltersAppListVersion";
import Checkbox from "../components/AppListVersion/Filters/Checkbox";

function AppDetailsDay() {
    const [loading, setLoading] = useState(true);
    const [selectedOption, setSelectedOption] = useState(null);
    const [countryData, setCountryData] = useState();
    const [selectStates, setSelectStates] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const [dateRange, setDateRange] = useState([
        new Date(Date.now() - 8 * 24 * 60 * 60 * 1000),
        new Date(Date.now() - 24 * 60 * 60 * 1000),
    ]);
    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                const responseCountry = await axios.get(
                    `https://gpanalytic.net/analytic/getAllCountries`,
                    {
                        headers: {
                            Authorization: 'Token 0a7b58445700962080613374fe7144d5290343b6',
                        }
                    }
                );

                setCountryData(responseCountry?.data.Countries);

                setLoading(false)
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDataFromApi().then(() => {});
    }, []);


    return (
        loading ? <div className="loader_page"><div className="lds-dual-ring"></div></div> :
            <div className="page apps versions">
                <FiltersAppListVersion
                    countryData={countryData}
                    loading={[loading, setLoading]}
                    selectedOption={[selectedOption, setSelectedOption]}
                    selectStates={[selectStates, setSelectStates]}
                    dateRange={[dateRange, setDateRange]}
                />
                <Checkbox
                    checkboxState={[checkboxState, setCheckboxState]}
                    loading={[loading, setLoading]}
                />
            </div>
    );
}

export default AppDetailsDay;