export const TableHeader = [
    {className: '', name: 'Trials'},
    {className: '', name: 'Direct sub'},
    {className: '', name: 'Paid trials'},
    {className: '', name: 'TrialtoSub %'},
    {className: 'position', name: 'ARPU 1'},
    {className: 'position', name: 'ARPU 2'},
    {className: 'position', name: 'ARPU 12'},
    {className: 'position', name: 'ARPU Ad 1 day'}
];

export default TableHeader;